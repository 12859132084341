import "core-js/modules/es.array.push.js";
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { mixins } from "@/plugins/mixins";
import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },

  mixins: [mixins],
  data() {
    return {
      selectId: null,
      //选择的停车点
      roadSectionId: null,
      // 选择的路段
      areaId: null,
      // 区域主键
      treeData: [],
      props: {
        children: 'children',
        label: 'label',
        allLabel: 'allLabel'
      },
      workTime: '',
      currentMonth: null,
      datas: [],
      calendarOptions: {},
      currentMonthStart: null,
      currentMonthEnd: null,
      dialogFormVisible: false,
      queryForm: {
        roadSectionName: null,
        roadPointName: null
      },
      filterText: ''
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    }
  },
  methods: {
    getDatas(currentStart, currentEnd) {
      const loading = ElLoading.service();
      this.$api.business.holiday.getList({
        start: currentStart,
        end: currentEnd
      }).then(res => {
        this.calendarOptions.events = [];
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            for (let x = 0; x < res.data.length; x++) {
              let db = res.data[x];
              this.calendarOptions.events.push({
                id: db.id,
                title: db.holidayName,
                start: db.dataDay + ' 00:00:00',
                end: db.dataDay + ' 23:59:59',
                background: 'red'
              });
            }
          } else {
            this.calendarOptions.events = [];
          }
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    initView() {
      this.calendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        firstDay: 1,
        events: [],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        buttonText: {
          today: '今天',
          month: '月视图',
          week: '周视图',
          day: '日视图',
          list: '列表视图'
        },
        customButtons: {
          prev: {
            click: () => {
              this.prevWeekClick();
            }
          },
          next: {
            click: () => {
              this.nextWeekClick();
            }
          },
          dayGridMonth: {
            text: '月视图',
            click: () => {
              let calendarApi = this.$refs['myCalendar'].getApi();
              calendarApi.changeView('dayGridMonth'); // 查询数据
              const currentStart = calendarApi.currentData.dateProfile.currentRange.start;
              const currentEnd = calendarApi.currentData.dateProfile.currentRange.end;
              this.getDatas(currentStart.format('yyyy-MM-dd'), currentEnd.format('yyyy-MM-dd'));
            }
          },
          today: {
            text: '今天',
            click: () => {
              let calendarApi = this.$refs['myCalendar'].getApi();
              calendarApi.today();
              const startTime = calendarApi.view.activeStart;
              const endTime = calendarApi.view.activeEnd;
              // 查询数据
              const currentStart = calendarApi.currentData.dateProfile.currentRange.start;
              const currentEnd = calendarApi.currentData.dateProfile.currentRange.end;
              this.getDatas(currentStart.format('yyyy-MM-dd'), currentEnd.format('yyyy-MM-dd'));
              this.$emit('clickPreNext', startTime, endTime);
            }
          },
          timeGridWeek: {
            text: '周视图',
            click: () => {
              let calendarApi = this.$refs['myCalendar'].getApi();
              calendarApi.changeView('timeGridWeek');
              // 查询数据
              const currentStart = calendarApi.currentData.dateProfile.currentRange.start;
              const currentEnd = calendarApi.currentData.dateProfile.currentRange.end;
              this.getDatas(currentStart.format('yyyy-MM-dd'), currentEnd.format('yyyy-MM-dd'));
            }
          }
        },
        timeZone: 'local',
        locale: 'zh-cn',
        // 切换语言，当前为中文
        displayEventEnd: true,
        //所有视图显示结束时间
        allowContextMenu: false,
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: false,
        weekends: true,
        // select: this.handleDateSelect,
        // eventClick: this.handleEventClick,//当用户点击日期或时间时触发的事件
        nowIndicator: true
      };
    },
    // 上周点击
    prevWeekClick() {
      let calendarApi = this.$refs['myCalendar'].getApi();
      calendarApi.prev();
      const startTime = calendarApi.view.activeStart;
      const endTime = calendarApi.view.activeEnd;
      // 查询数据
      const currentStart = calendarApi.currentData.dateProfile.currentRange.start;
      const currentEnd = calendarApi.currentData.dateProfile.currentRange.end;
      this.currentMonthStart = currentStart.format('yyyy-MM-dd');
      this.currentMonthEnd = currentEnd.format('yyyy-MM-dd');
      this.getDatas(this.currentMonthStart, this.currentMonthEnd);
      this.$emit('clickPreNext', startTime, endTime); //拿到的日期格式传给父组件
    },

    // 下周点击
    nextWeekClick() {
      let calendarApi = this.$refs['myCalendar'].getApi();
      calendarApi.next();
      const startTime = calendarApi.view.activeStart;
      const endTime = calendarApi.view.activeEnd;
      // 查询数据
      const currentStart = calendarApi.currentData.dateProfile.currentRange.start;
      const currentEnd = calendarApi.currentData.dateProfile.currentRange.end;
      this.currentMonthStart = currentStart.format('yyyy-MM-dd');
      this.currentMonthEnd = currentEnd.format('yyyy-MM-dd');
      this.getDatas(this.currentMonthStart, this.currentMonthEnd);
      this.$emit('clickPreNext', startTime, endTime);
    }
  },
  beforeMount() {
    this.initView();
    let cud = new Date();
    let y = cud.getFullYear();
    let m = cud.getMonth();
    let d = new Date(y, m, 0).getDate();
    this.getDatas(cud.format('yyyy-MM') + '-01', cud.format('yyyy-MM') + '-' + d);
  }
};